import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import Banner from "../components/Banners/RemontVRassrochku"
import Advantages from "../components/Advantages"
import Discounts from "../components/Discounts"
import Conditions from "../components/Conditions"
import WaysToGetCard from "../components/WaysToGetCard"
import Partners from "../components/Partners"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { FormPKW } from "../components/FormPKW"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout ligal={ligal} phones={phones} noIndex={noIndex}>
      <Banner orderNum="2" />
      <Advantages orderNum="3" />
      <Discounts orderNum="4" />
      <Conditions rate={halvaOstatok.value} orderNum="5" />
      <WaysToGetCard orderNum="6" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title="Оформить заявку"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="7"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <Partners orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/remont-v-rassrochku/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
