import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import * as styles from "./remontVRassrochku.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Заказать карту"

type BannerProps = {
  orderNum?: string
}

export default function Banner({ orderNum }: BannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.root}>
        <h1 className={styles.head}>Рассрочка 0% на товары для ремонта</h1>
        <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
